import DiffMatchPatch from "diff-match-patch";
import RaceDependentState from "@/js-code/states/abstract/raceDependentState";

export default class LivetimingState extends RaceDependentState {
  constructor(race, setResults) {
    super(race);
    this.setResults = setResults;

    this.currentState = [];
    this.diffMatchPatch = new DiffMatchPatch();
  }

  updateContent(content)
  {
    this.currentState = content;

    this.setResults(this.currentState);
  }

  mergeContent(content)
  {
    //console.log('try merge (3)');
    if (!content || (content.increments.length !== this.currentState.results.length && content.increments.length !== 0))
      return false;


    //console.log('try merge (4)');
    // if no increments are transferred, maybe the event state is delivered on its own
    if (content.increments.length !== 0)
    {
      let patchedContent = [];
      for (let x=0; x<content.increments.length; x++)
      {
        if (content.increments[x].Name !== this.currentState.results[x].Name)
          return false;

        //console.log(content.increments[x].Patches);
        //const oldContent = this.currentState.results[x].Content;

        if (content.increments[x].Patches.length > 0)
        {
          const patchResult =  this.diffMatchPatch.patch_apply(content.increments[x].Patches, this.currentState.results[x].Content);
          patchedContent.push(patchResult[0]);
        }
        else
          patchedContent.push(this.currentState.results[x].Content);

        //if (content.increments[x].Patches.length > 0)
        //{
        //  console.log(content.increments[x].Name);
        //  console.log(oldContent);
        //  console.log(patchedContent[x]);
        //  JSON.parse(patchedContent[x]);
        //}
      }

      this.currentState.eventState = content.eventState;

      for (let x=0; x<content.increments.length; x++)
        this.currentState.results[x].Content = patchedContent[x];
      //console.log('merged!');
    }

    this.setResults(this.currentState);

    return true;
  }

  updateState(content, stateIndicator)
  {
    this.updateContent(content);

    this.stateIndicator = stateIndicator;
  }
}